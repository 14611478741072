.content-icon{
    background: transparent;
}

.col-menu-option{
    width: 10.1% !important;
    cursor: pointer;
    padding: 5px 0px 5px 0px;
    position: relative;
}
/* .col-menu-option:hover {
    opacity: .8;
    border: 1px solid #000;
    border-radius: 10px;
} */
.col-menu-option h5{
    margin-bottom: 0;
    font-size: 12px;
}

.container-menu-options{
    position: relative;
}

.btn-clean-session{
    position: absolute;
    right: 0px;
    top: 20px; 
}

.btn-clean-session p{
    margin: 0;
}

.content-consent-signature{
    display: inline-flex;
    color: red;
    position: absolute;
    top: 0;
    right: 20px;
}

.content-consent-signature svg{
    font-size: 35px;
}


.iconUser_st1{
    fill:#0050FF;
}
.iconUser_st0,
.icontest_st0,
.iconGoals_st0,
.iconFicha_st0{
    fill-rule:evenodd;
    clip-rule:evenodd;
    fill:#0050FF;
}

.box-menu-options{
    border: 1px solid #ced4da;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
